import React from 'react';
import { Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { useAppContext } from '../../state';

import { useSiteConfig } from '../../GraphQl/useSiteConfig';

import { NavLink } from './navLink';

import Logo from '../../svg/logo.svg';
import ArrowUp from '../../svg/arrow-up.svg';

const isClient = typeof window === 'object';

const FooterDesktop = ({ menuItems, quote, copyrightText }) => {
  const { setCursorHover } = useAppContext();

  const scrollUp = () => {
    if (!isClient) {
      return false;
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className="grid grid-cols-14 grid-rows-1" data-scroll-section>
      <div className="col-start-1 col-end-12 bg-footer-bg row-start-1 " />
      <div className="col-start-2 col-end-12 row-start-1 pr-20">
        <div className="flex justify-between py-20">
          <h2 className="text-white text-5xl max-w-md leading-snug">{quote}</h2>
          <Link
            className="dj-btn self-end text-footer-bg"
            to="/contact"
            onMouseEnter={() => setCursorHover(true)}
            onMouseLeave={() => setCursorHover(false)}
            onClick={() => setCursorHover(false)}
          >
            Say Hello
          </Link>
        </div>
        <div className="flex justify-between border-b border-white pb-6 border-opacity-50">
          <Link
            to="/"
            className="w-32"
            onMouseEnter={() => setCursorHover(true)}
            onMouseLeave={() => setCursorHover(false)}
            onClick={() => setCursorHover(false)}
          >
            <Logo className="text-pull-color fill-current" />
          </Link>
          <nav className="flex self-end">
            {menuItems.map((item) => (
              <NavLink
                {...item}
                className="text-white ml-10 uppercase text-xl"
                onMouseEnter={() => setCursorHover(true)}
                onMouseLeave={() => setCursorHover(false)}
                onClick={() => setCursorHover(false)}
              />
            ))}
          </nav>
        </div>
        <div className="py-20">
          <p className="text-white text-xl uppercase">{copyrightText}</p>
        </div>
      </div>
      <div className="col-start-12 col-end-14 row-start-1 self-end justify-self-end pb-10">
        <button
          onClick={scrollUp}
          className="outline-none focus:outline-none"
          onMouseEnter={() => setCursorHover(true)}
          onMouseLeave={() => setCursorHover(false)}
        >
          <ArrowUp className="w-4 mx-auto mb-3 fill-current text-dejima-text" />
          <span className="vert-text uppercase text-xl font-medium">Scroll to top</span>
        </button>
      </div>
    </footer>
  );
};

const MobileFooter = ({ quote, menuItems, copyrightText }) => {
  return (
    <footer className="px-gutter py-10" data-scroll-section>
      <div className="bg-footer-bg text-white text-center py-32 mb-10">
        <h2 className=" text-3xl leading-tight px-10 mb-5">{quote}</h2>
        <Link className="dj-btn self-end" to="/contact">
          Say Hello
        </Link>
      </div>
      <div className="border-t border-dejima-green border-opacity-40 pt-10">
        <Link to="/" className="w-24 block">
          <Logo className="text-pull-color fill-current" />
        </Link>
        <nav className="grid gap-y-2 my-10">
          {menuItems.map((item) => (
            <NavLink {...item} className="uppercase text-xl" />
          ))}
        </nav>
        <p className="text-xl uppercase">{copyrightText}</p>
      </div>
    </footer>
  );
};

export const Footer = () => {
  const { footerNav, footerQuote, copyrightText } = useSiteConfig();
  const breakpoints = useBreakpoint();

  return breakpoints.xs || breakpoints.sm || breakpoints.md ? (
    <MobileFooter menuItems={footerNav} quote={footerQuote} copyrightText={copyrightText} />
  ) : (
    <FooterDesktop menuItems={footerNav} quote={footerQuote} copyrightText={copyrightText} />
  );
};
