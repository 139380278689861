import React from 'react';
import Image from 'gatsby-plugin-sanity-image';

// Hooks
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

export const ResponsiveImage = ({ imageLarge, imageSmall, ...rest }) => {
  const breakpoints = useBreakpoint();

  return breakpoints.sm ? <Image {...imageSmall} {...rest} /> : <Image {...imageLarge} {...rest} />;
};
